/*------------------------------------------------*/
/* LIBRARIES
/*------------------------------------------------*/
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _get from 'lodash/get';
import _uniqueId from 'lodash/uniqueId';
/*------------------------------------------------*/
/* INTERNAL DEPENDENCIES
/*------------------------------------------------*/
// Style
import MediaBreakDown from '../../styles/MediaBreakDown';
import theme from '../../styles/theme';


const CardWrapper = styled.div`
  background: ${theme.background.light};
  border: 1px solid ${theme.borderColor.grayBorder};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  border-left: 10px solid ${props => _get(props, 'cardColor', '')};
  padding: 30px;
  text-align: left;

  .title {
    font-weight: 700;
    font-size: 36px;
    line-height: 43px;
    color: ${theme.textColor.blackText};
  }

  .do-word {
    color: ${props => _get(props, 'cardColor', '')};
  }

  .items-wrapper {
    margin: 0;
    list-style: none;

    li {
      line-height: 20px;
      margin: 0 0 16px 0;

      &:last-child {
        margin: 0;
      }
    }

    a {
      color: ${theme.textColor.blueText};
    }
  }
  ${MediaBreakDown.xl`
    max-width: 400px;
  `}
  ${MediaBreakDown.lg`
    max-width: initial;
  `}
`;
CardWrapper.displayName = 'CardWrapper';


const Card = (props) => {
  const {
    isDo,
    items,
  } = props;
  const doWord = isDo ? 'DO' : 'DON\'T';
  const doColor = isDo ? '#2EB67D' : '#DD4B4B';


  const getItems = React.useCallback(
    () => {
      if (!items || !Array.isArray(items) || (Array.isArray(items) && items.length === 0)) {
        return null;
      }

      const mappedItems = items.map(item => (
        <li key={_uniqueId()}>{item}</li>
      ));

      return (<ul className="items-wrapper">{mappedItems}</ul>);
    },
    [items]
  );

  return (
    <CardWrapper cardColor={doColor}>
      <div className="title-wrapper">
        <h4 className="title">
          Please
          {' '}
          <span className="do-word">{doWord}</span>
          :
        </h4>
        {getItems()}
      </div>
    </CardWrapper>
  );
};


/*------------------------------------------------*/
/* PROP TYPES
/*------------------------------------------------*/
Card.defaultProps = {
  isDo: false,
  items: [],
};
Card.propTypes = {
  isDo: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.element,
    ])
  ),
};


/*------------------------------------------------*/
/* EXPORTS
/*------------------------------------------------*/
export default Card;
